<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-container fluid>
      <div class="text-center">
        <span class="text-h3">{{ $t(title) }}</span>
      </div>
    </v-container>
    <div>
      <dispenser-form
        type="edit"
        :read-only="isPermission('fuel-tanks.view')"
        :item="processedItem"
        :loading="isLoading"
        @onclose="closeForm"
        @onsave="updateItem"
      />
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    DispenserForm: () => import("../utils/DispenserForm.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),

    processedItem() {
      return this.replaceNAWithNull(this.item);
    },

    title() {
      return this.isPermission("locations.view") ? "view" : "edit";
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.options = { ...this.item };
    }
  },
  methods: {
    replaceNAWithNull(item) {
      if (!item || typeof item !== "object") return item;
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, value === "N/A" ? null : value])
      );
    },
    isPermission(val) {
      return this.$admin.can(val);
    },
    async updateItem(row) {
      this.isLoading = true;
      await this.$store
        .dispatch("fuelDispenser/update", row)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoading = false;
          this.toggleEdit();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        });
    },
    closeForm() {
      this.toggleEdit();
    },
  },
};
</script>